
.submenu-element{
  padding: 15px 20px;
}

.submenu-element:hover{
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 4px;
}

.submenu-element .title{
  font-weight: bold;
}

.submenu-element .description{
  margin-top: 4px;
  font-weight: normal;
  font-size: .95em;
  color: #999999;
}


