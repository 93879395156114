

.devicePresentation{
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  font-size: .95em;
  display: flex;
  flex-wrap: wrap;
}

.devicePresentation-icon{
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-right: 20px;
}



.device-type{
  position: absolute;
  right: 15px;
}

.device-type .web{
  text-transform: uppercase;
  background-color: #321fdb;
  color: white;
  border-radius: 5px;
  font-size: 0.7em;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.device-type .mobile{
  text-transform: uppercase;
  background-color: var(--green-kamix);
  color: white;
  border-radius: 5px;
  font-size: 0.7em;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}



.devicePresentation-type-custom{
  text-transform: uppercase;
  font-weight: bolder;
  color: black;
  font-size: 0.9em;
}

.devicePresentation-type-custom .yes, .devicePresentation-type-custom .on{
  color: var(--green-kamix);
}

.devicePresentation-type-custom .no, .devicePresentation-type-custom .off{
  color: var(--red-kamix);
}

.devicePresentation-type-custom .on_authorization{
  color: black;
}



.devicePresentation .main-device{
  border-radius: 8px;
  background-color: var(--yellow-kamix);
  padding: 3px 10px;
  color: black;
  font-weight: 500;
  font-size: .8em;
  margin-right: 5px;
}

.devicePresentation .badge{
  border-radius: 8px;
  background-color: var(--green-kamix);
  padding: 5px 10px;
  color: white;
  font-weight: normal;
  font-size: .8em;
  margin-right: 5px;
}

@media screen and (max-width: 400px) {

  .devicePresentation-icon{
    display: flex;
    justify-content: center;
    margin: 10px 20px 10px 10px;
  }

  .infos{
    margin: 10px 0;
  }

}