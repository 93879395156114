
.statistic-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  background: var(--linear-green);
  height: 70px;
}

.statistic-card .title{
  font-size: .8em;
}

.statistic-card .value{
  font-size: 1.5em;
  font-weight: bold;
  color: var(--green-kamix);
}

