
:root {
  --light-green-kamix: #ECF5E6;
  --green-kamix-3: rgba(94, 146, 61, 0.3);
  --green-kamix: #5e923d;
  --linear-green: linear-gradient(0deg, #DFF1E3, #DFF1E3), linear-gradient(167.96deg, #FFC820 0%, #5E923D 100%);

  --red-kamix-3: rgba(191,38,38,0.3);
  --red-kamix: #BF2626;
  --linear-red: linear-gradient(0deg, #FED1D1, #FED1D1), linear-gradient(167.96deg, #FFC820 0%, #5E923D 100%);

  --yellow-kamix-3: rgba(255,201,30,0.3);
  --yellow-kamix: #ffc91e;
  --linear-yellow: linear-gradient(0deg, #FEF6D1, #FEF6D1), linear-gradient(167.96deg, #FFC820 0%, #5E923D 100%);
  
  --warning-kamix: #fb6340;
  --medium-black: #4D4D4D;
  --default-background: #F5F7FA;
  --default-background-2: #FAFAFA;
}

body, .main{
  background-color: var(--default-background-2) !important;
}

.main{
  padding: 25px;
}

* {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
  letter-spacing: -0.5px;
}

.cursorView{
  cursor: pointer;
}

.start-button{
  border-radius: 8px;
  font-weight: 500;
}

.my-button{
  min-width: 167px;
  border-radius: 8px;
  /*border: none;*/
  font-weight: 500;
}

.my-button-with-inherit-width{
  border-radius: 8px;
  /*border: none;*/
  font-weight: 500;
}

.special-button{
  border-radius: 8px;
  /*border: none;*/
  font-weight: 500;
}

.my-button-2{
  border-radius: 8px;
  /*border: none;*/
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-kamix{
  border: 1px solid rgba(0,0,0,.2);
}

.border-bottom-kamix{
  border-bottom: 1px solid rgba(0,0,0,.2)!important;
}

.border-radius-kamix{
  border-radius: 8px !important;
}

.border-radius-kamix-2{
  border-radius: 4px !important;
}

.bg-yellow-kamix{
  background-color: var(--yellow-kamix) !important;
}

.bg-green-kamix{
  background-color: var(--green-kamix) !important;
}

.bg-red-kamix{
  background-color: var(--red-kamix) !important;
}

.bg-linear-green{
  background: var(--linear-green);
}

.bg-gray-kamix{
  background: #999999 !important;
}

.bg-linear-yellow{
  background: var(--linear-yellow);
}

.bg-linear-red{
  background: var(--linear-red);
}

.icon-sm{
  color: black;
  font-size: 1.5em;
}

.text-normal{
  color: var(--medium-black);
  font-size: 1em;
}

.my-input{
  background-color: var(--default-background);
  border-radius: 8px;
  min-height: 45px;
  color: var(--medium-black);
  font-size: 1em;
  width: 100%;
}

.my-input-white{
  background-color: white;
  border-radius: 8px;
  min-height: 45px;
  color: var(--medium-black);
  font-size: 1em;
  width: 100%;
}

.ant-form-item-explain-error{
  font-size: 0.8em;
}

.react-tel-input {
  font-size: 0.9rem !important;
  border-radius: 8px;
}

.react-tel-input .flag-dropdown {

}

.react-tel-input .form-control {
  height: 2rem !important;
  position: relative;
  letter-spacing: .01rem;
  border-radius: 8px !important;
  font-size: 0.9em !important;
  color: var(--medium-black);
}

.react-code-input input:nth-child(4){
  background-color: red;
  margin: 500px;
}

.text-underline{
  text-decoration: underline;
}

.flag-icon-presentation{
  width: 25px;
  height: auto;
}

.objectFitCover{
  object-fit: cover;
}

.breakWord{
  word-break: break-all;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.mr-auto{
  margin-right: auto;
}

.ml-auto{
  margin-left: auto;
}

.one-line{
  overflow: hidden;
  white-space: nowrap;
}

.text-justify{
  text-align: justify;
}

.my-box{
  border-radius: 8px;
  border:1px solid rgba(0,0,0,.2);
}

.my-box-2{
  border-radius: 8px;
  border:1px solid rgba(0,0,0,.2);
}

.text-ellipsis{
  overflow: hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
}

.text-italic{
  font-style: italic;
}

.text-green-kamix{
  color: var(--green-kamix);
}

.text-yellow-kamix{
  color: var(--yellow-kamix);
}

.text-red-kamix{
  color: var(--red-kamix);
}

.text-warning-kamix{
  color: var(--warning-kamix);
}

.text-gray-kamix{
  color: #999999;
}

.my-input input[type=password], .my-input input[type=text]{
  background-color: var(--default-background);
  color: var(--medium-black);
}

.ant-input-affix-wrapper-status-error input[type=password], .ant-input-affix-wrapper-status-error input[type=text]{
  background-color: white;
}

.my-link, .my-link:hover{
  text-decoration: underline;
  color: var(--green-kamix);
}

.kamix-logo {
  width: 100px;
  height: auto;
  cursor: pointer;
}

.margin-bottom-25{
  margin-bottom: 25px;
}

.right-block{
  width: 100%;
  height: 100%;
  background-color: black;
}

.full-width{
  width: 100%;
}

.half-width{
  width: 50%;
}

.kamix-sm-icon{
  font-size: 40px;
  padding: 0 5px;
}

.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}

.status-incomplete, .status-inactive, .status-not_confirmed, .status-deleted{
  font-weight: bold;
  color: black;
}

.status-filled, .status-pending{
  font-weight: bold;
  color: gray;
}

.status-accepted, .status-active, .status-validated{
  font-weight: bold;
  color: var(--green-kamix);
}

.status-rejected, .status-blocked{
  font-weight: bold;
  color: var(--red-kamix);
}

.child:last-child{
  margin-bottom: 0;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ok-button{
  border-radius: 8px;
  border: none;
  font-weight: normal;
  color: black !important;
  background: var(--yellow-kamix) !important;
  text-transform: lowercase;
}

.tooltip-icon{
  background-color: var(--medium-black);
  color: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px
}

.dangerouslySetInnerHTML img{
  max-width: 100%;
}

.bar{
  border-left: 5px solid gray;
  border-radius: 4px;
  padding-left: 10px;
}

@media screen and (max-width: 576px) {

  .start-button{
    width: 95%;
  }

}

@media screen and (min-width: 577px) {

  .start-button{
    width: 60%;
  }

}

@media screen and (min-width: 992px) {

  .start-button{
    width: 30%;
  }

}