

.text-mobile{
  color: #999999;
}

.abbreviation, .createReceiverButton{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: bolder;
  font-size: 15px;
  margin-right: 15px;
}

.createReceiverButton{
  background-color: var(--light-green-kamix);
  color: var(--green-kamix);
}

.receiverPresentation{
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: .95em;
}

.receiverPresentation:hover{
  background-color: #f0f0f0;
  cursor: pointer;
}
