

.hello-box{
  font-style: normal;
  font-weight: 600;
  font-size: 1.75em;
  color: #000000;
  margin-bottom: 20px;
}

.balance-box{
  display: flex;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  background: white;
  border-radius: 8px;
  margin-bottom: 16px;
}

.balance-box .amount{
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  color: #000000;
  margin-bottom: 5px;
}

.balance-box .amount-title{
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  color: #999999;
}

.action-box-1, .action-box-2{
  height: 180px;
  padding: 16px;
}

.action-box-1 .title, .action-box-2 .title{
  font-style: normal;
  font-weight: 500;
  font-size: 1.3em;
  color: #000000;
}

.action-box-1 .icon, .action-box-2 .icon{
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--green-kamix);
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  bottom: 16px;
  cursor: pointer;
}

.action-box-1{
  background: #ECF5E6;
}

.action-box-2{
  background: #FFF9E5;
}

.missing-information{
  margin: 20px 0 16px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1.1em;
  color: #000000;
}

.transfer-box-parent{
  display: flex;
  justify-content: center;
}

.transfer-box-parent .transfer-box{
  text-align: center;
  padding: 10px;
  background: white;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.documents-missing-box-layout{
  display: flex;
  width: available;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 50px;
}

.documents-missing-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  width: 300px;
  background: #FFFFFF;
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 15px;
  min-width: 190px;
}

.documents-missing-box .fr{
  height: 200px;
}

.documents-missing-box .sub-box-fr{
  height: 140px;
  margin-bottom: 5px;
}

.documents-missing-box .en{
  height: 180px;
}

.documents-missing-box .sub-box-en{
  height: 120px;
  margin-bottom: 5px;
}

.documents-missing-box .title{
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  color: black;
}

.documents-missing-box .description{
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  color: #4D4D4D;
}

.documents-missing-box button{
}

.documents-missing-box-layout::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F5F5F5;
}

.documents-missing-box-layout::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #F5F5F5;
}

.documents-missing-box-layout::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--medium-black);
}



@media screen and (max-width: 576px) {

  .documents-missing-box{
    width: 100%;
  }

  .missing-information{
    margin-bottom: 40px;
  }

}