
.creditCardPresentation{
  display: flex;
  align-items: flex-start;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: .95em;
}

.creditCardPresentation:hover{
  background-color: #f0f0f0;
  cursor: pointer;
}

.my-badge{
  font-size: 0.7em;
  padding: 3px 5px;
  border-radius: 8px;
  background-color: rgba(108,117,125,0.3);
  color: black;
  font-weight: 500;
  width: fit-content;
}

.general-badge{
  padding: 3px 5px;
  border-radius: 8px;
  background-color: rgba(13, 110, 253,0.08);
  color: black;
  font-weight: 500;
  width: fit-content;
}

.frame-input{
  background-color: var(--default-background);
  border-radius: 8px;
  border: 1px solid lightgray;
  color: black;
  width: 100%;
  height: 50px;
}

