
.solvedMessage{
    background-color: var(--green-kamix);
    color: white;
    border-radius: 5px;
    font-size: 0.8em;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
}

.dropdownButton{
    background-color: #ededed;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dropdownButton2{
    background-color: #ededed;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dropdownButtonClicked{
    background-color: rgba(0,0,0,0.1);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.myDropdown{
    background-color: white;
    z-index: 2000;
    position: absolute;
    right: 0px;
    top:45px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
    border: 1px solid rgba(0,0,0,.2);
}

.dropdownOption{
    cursor: pointer;
    padding: 8px 20px;
    color:black;
    width: 150px;
}

.dropdownOption:hover{
    background-color: rgba(0,0,0,0.3);
    color:white;
}



#chat-box{
    display: flex;
    flex-flow: column;
    background-color: #e7ddd5;
    border-radius: 8px;
}

#chat-body{
    padding: 20px 60px;
    height: 62vh;
    overflow-y: scroll;
}

.scrollButton{
    position: absolute;
    top: 62vh;
    left: 94%;
    z-index: 20;
    cursor: pointer;
}

#chat-body::-webkit-scrollbar {
    width: 7px;
    height: 10px;
}

#chat-body::-webkit-scrollbar-track {
    background: #e7ddd5;
}

#chat-body::-webkit-scrollbar-thumb {
    background: #888;
}


#chat-bottom{
    border-top: solid lightgray 2px;
    background-color: #ededed;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 8px 8px;
}


#chat-input-container{
    width: 100%;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    justify-content: center;
}

#chat-input{
    width: 95%;
    color: rgba(0,0,0,0.8);
    font-size: 0.9em;
    padding: 5px;
    min-height: 40px;
    max-height: 50px;
    overflow: scroll;
    margin-bottom: 0;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
}

#chat-input::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

#chat-input::-webkit-scrollbar-track {
    background: #ffffff;
}

#chat-input::-webkit-scrollbar-thumb {
    background: #888;
}



{/*---------------------------------------------------------*/}
{/*---------------------------------------------------------*/}
{/* Message Presentation */}
{/*---------------------------------------------------------*/}
{/*---------------------------------------------------------*/}


.messageTextPresentationUser{
    background-color: #d4f9c8;
    width: fit-content;
    max-width: 80%;
    border-radius: 4px;
    padding: 5px 10px;
    margin-left: auto;
}

.messageTextPresentationAdmin{
    background-color: white;
    width: fit-content;
    max-width: 80%;
    border-radius: 4px;
    padding: 5px 10px;
}

.messageMultimediaPresentationUser{
    background-color: #d4f9c8;
    width: fit-content;
    max-width: 60%;
    border-radius: 4px;
    padding: 10px 5px 1px 5px;
    margin-left: auto;
}

.messageMultimediaPresentationAdmin{
    background-color: white;
    width: fit-content;
    max-width: 60%;
    border-radius: 4px;
    padding: 10px 5px 1px 5px;
}

.footerMessage{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 0.67em;
}

.multimediaPreview{
    max-width: 100%;
    max-height: 50vh;
}


{/*---------------------------------------------------------*/}
{/*---------------------------------------------------------*/}
{/*---------------------------------------------------------*/}
{/*---------------------------------------------------------*/}


@media screen and (max-width: 991px) {

    .messagePresentationUser, .messagePresentationAdmin{
        font-size: 0.8em;
        background-color: #d4f9c8;
        width: fit-content;
        max-width: 80%;
        border-radius: 10px;
        padding: 5px 10px;
        margin-left: auto;
    }

    #chat-body::-webkit-scrollbar {
        width: 5px;
        height: 10px;
    }

    #chat-body{
        padding: 15px;
    }

    .scrollButton{
        position: absolute;
        top: 62vh;
        left: 90%;
        z-index: 20;
        cursor: pointer;
    }

}