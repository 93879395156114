
.icon{
    width: 20px;
    margin-right: 5px;
}

.icon-rs{
    max-width: 50px;
    height: auto;
}

.status-small-text{
    font-size: .8em;
}

.identity-element{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 4px;
    padding: 5px 0;
    margin-bottom: 10px;
}

.identity-element:hover{
    background-color: var(--default-background-2);
}

.chooseAFile{
    cursor: pointer;
    padding: 3px 5px;
    background-color: var(--green-kamix);
    color: #ffffff;
    border-radius: 5px;
}

.myFile{
    cursor: pointer;
    color: white;
    background-color: #172b4d;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.8em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}
