

.header{
  background-color: white;
}

.logoBox {
  margin: 13px 50px 13px 0;
  cursor: pointer;
}

.kamixLogo {
  width: 85px;
  height: auto;
}

.my-navbar{
  display: flex;
}

a, a:hover{
  color: var(--medium-black);
  text-decoration: none;
}

.my-navlink, .my-navlink-active{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  height: 64px;
  cursor: pointer;
}

.my-navlink-active{
  border-top: 3px solid var(--green-kamix);
  color: var(--green-kamix);
  padding-bottom: 4px;
}

.my-navlink-2, .my-navlink-active-2{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding-left: 15px;
  width: 130px;
}

.my-navlink-2{
  margin-left: 3px;
}

.my-navlink-active-2{
  border-left: 3px solid var(--green-kamix);
  color: var(--green-kamix);
  padding-bottom: 4px;
}

.my-navlink-active .my-navbar-icon path, .my-navlink-active-2 .my-navbar-icon path{
  fill: #5e923d;
}

.my-navlink .my-navbar-icon path, .my-navlink-2 .my-navbar-icon path{
  fill: #999999;
  stroke: #999999;
}


.my-navbar-icon{
  font-size: 20px;
  margin-right: 5px;
}

.my-navbar-title{
  margin-top: 5px;
  font-weight: 500;
  font-size: 1em;
}

.my-navbar-buttons{
  display: flex;
  margin-left: auto;
}

.responsive-menu-button{
  font-size: 18px;
  cursor: pointer;
  border: 2px solid black;
  padding: 2px;
}


@media screen and (max-width: 1200px){

  .my-navbar-icon{
    font-size: 17px;
    margin-right: 5px;
  }

  .my-navbar-title{
    margin-top: 5px;
    font-weight: 500;
    font-size: 0.95em;
  }

  .d-none-max-1200{
    display: none;
  }

}