

.summary-box{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  background: white;
}

.summary-box .text{
  font-weight: 500;
  font-size: 1em;
  color: #999999;
}

.summary-box .amount{
  font-weight: 600;
  font-size: 1.75em;
  margin: 5px 0;
  color: #000000;
}


{/* ------------------------------------------------------  */}
{/* ------------------------------------------------------  */}
{/* Tx Presentation */}
{/* ------------------------------------------------------  */}
{/* ------------------------------------------------------  */}


.txPresentation{
  margin: 5px 10px;
  padding: 10px 15px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  border-radius: 4px;
}

.txPresentation:hover{
  background-color: #f0f0f0;
}

.tx-badge{
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.icon-block{
  width: 40px;
  text-align: center;
}

.circle{
  background-color: var(--green-kamix);
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 25px;
  left: 25px;
}

.circle-stepper{
  background-color: var(--green-kamix);
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.removeForSmallScreen{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.displayForSmallScreen{
  display: none;
}

.displayForSmallScreen2{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: auto;
  width: 50%;
}

.amount{
  font-weight: 600;
  font-size: 1.75em;
  margin: 5px 0;
}

.url{
  font-size: 0.95em;
  text-decoration: underline;
  color: var(--green-kamix);
}

.special-spacing{
  margin-bottom: 15px;
  padding: 20px;
}

.border-logo{
  border: 1px solid var(--green-kamix);
}

.tink-card{
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 5px 10px 10px 10px;
  margin-top: 5px;
  width: fit-content;
}

.tink-country{
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 5px 10px;
  margin-top: 5px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tink-flag{
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 6px 10px;
  margin-top: 5px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tink-url{
  width: 250px;
  overflow: hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
}


@media screen and (max-width: 768px){

  .removeForSmallScreen{
    display: none;
  }

  .displayForSmallScreen{
    display: flex;
    flex-direction: column;
  }

  .displayForSmallScreen2{
    width: 100%;
  }

}

