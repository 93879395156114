
.profile-element{
  border-radius: 8px;
  border:1px solid rgba(0,0,0,.2);
  background-color: white;
  padding: 15px 20px;
}

.profile-element .icon{
  width: 25px;
  height: 25px;
  margin-bottom: 8px;
}

.profile-element .title{
  font-weight: 400;
  font-size: .95em;
}

.invite-friends-box{
  min-height: 90px;
  border-radius: 8px;
  border:1px solid rgba(0,0,0,.2);
  background-color: white;
  padding: 10px 20px;
}

.invite-friends-box .title{
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  color: #000000;
}

.invite-friends-box .icon{
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--green-kamix);
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  bottom: 16px;
  cursor: pointer;
}

.invite-friends-box{
  background: #FFF9E5;
}