
.my-select .my-select__control {
  display: flex;
  text-align: center;
  align-items: center;
  width: 116px;
  min-height: 45px;
  background-color: var(--default-background);
  border-radius: 8px;
  cursor: pointer;
}

.my-select-2 .my-select-2__control {
  min-height: 45px;
  background-color: var(--default-background);
  border-radius: 8px;
  cursor: pointer;
}

.my-select-2 .my-select-2__placeholder  {
  color: rgba(128,128,128,.5);
}

.my-select-2-warning .my-select-2-warning__control {
  min-height: 45px;
  background-color: var(--default-background);
  border-radius: 8px;
  cursor: pointer;
  border-color: red;
}

.my-select .my-select__menu, .my-select-2 .my-select-2__menu {
  min-width: 250px;
}

.flag .icon{
  width: 25px;
  height: auto;
}

.flag .text{
  font-size: 13px;
  margin-left: 5px;
  font-weight: bold;
}