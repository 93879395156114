
.full-half-page{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
}

.padding-25{
  padding: 50px 25px;
}


{/* Start Page */}

.explanation-text{
  font-style: normal;
  font-weight: 600;
  font-size: 1.7em;
  text-align: center;
  margin-top: 30px;
}

.bg-start-1{
  background: var(--linear-green)
}

.bg-start-2{
  background: var(--linear-red)
}

.bg-start-3{
  background: var(--linear-yellow)
}

.start-header-line{
  height: 5px;
  border-radius: 5px;
  width: 98%;
}

.bg-step-1{
  background: var(--green-kamix-3);
}

.bg-step-2{
  background: var(--red-kamix-3);
}

.bg-step-3{
  background: var(--yellow-kamix-3);
}

.active-1{
  background: var(--green-kamix);
  width: 50%;
}

.active-2{
  background: var(--red-kamix);
  width: 50%;
}

.active-3{
  background: var(--yellow-kamix);
  width: 50%;
  z-index: 100;
}

.inactive-12{
  background: var(--red-kamix);
  width: 100%;
}

.inactive-23, .inactive-13{
  background: var(--yellow-kamix);
  width: 100%;
}

.illustration-box{
  display: flex;
  justify-content: center;
  align-items: center;
}

.illustration-box .image{
  max-width: 80%;
  height: auto;
}

.bottom-page-box{
  display: flex;
  justify-content: center;
}

.bottom-page-box .bottom-page-buttons{
  text-align: center;
  padding: 10px;
  position: fixed;
  bottom: 30px;
  width: 100%;
}

.carousel-icon{
  font-size: 1.875em;
  cursor: pointer;
}

.illustration{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 8px;
  height: 70vh;
}

.right-illustration{
  width: 100%;
  height: auto;
}

.text-header{
  font-weight: 600;
  font-size: 1.75em;
  margin-bottom: 10px;
}

.step-rounded{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  font-size: 10px;
  background: black;
  color: white;
  border-radius: 20px;
}

.do-it-later{
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  color: var(--green-kamix);
  cursor: pointer;
}

.copyright {
  font-size: 1em;
}

.fund-choice, .fund-choice-selected{
  background-color: var(--default-background);
  border-radius: 8px;
  min-height: 45px;
  color: #999999;
  font-size: 1em;
  border: none;
  cursor: pointer;
  text-align: center;
}

.fund-choice-selected{
  background-color: white;
  border: 2px solid var(--green-kamix);
  color: black;
}



.background-illustration-transfer-funds-to-africa{
  background-image: url("../illustrations/illustration_transfer_funds_to_africa.png");
  background-size: cover;
  background-position: center;
}

.background-illustration-kamix-home-page{
  background-image: url("../illustrations/illustration_kamix_home_page.png");
  background-size: cover;
  background-position: center;
}

.background-illustration-kamix-welcome-page{
  background-image: url("../illustrations/illustration_kamix_welcome_page.png");
  background-size: cover;
  background-position: center;
}

.background-illustration-02{
  background-image: url("../illustrations/illustration_02.png");
  background-size: cover;
  background-position: center;
}

.background-illustration-04{
  background-image: url("../illustrations/illustration_04.png");
  background-size: cover;
  background-position: center;
  background-color: var(--linear-yellow);
}

.background-illustration-05{
  background-image: url("../illustrations/illustration_05.png");
  background-size: cover;
  background-position: center;
  background-color: var(--linear-yellow);
}

.background-illustration-06{
  background-image: url("../illustrations/illustration_06.png");
  background-size: cover;
  background-position: center;
}

.background-illustration-07{
  background-image: url("../illustrations/illustration_07.png");
  background-size: cover;
  background-position: center;
}

.background-illustration-08{
  background-image: url("../illustrations/illustration_08.png");
  background-size: cover;
  background-position: center;
  background-color: var(--linear-green);
}

.background-illustration-09{
  background-image: url("../illustrations/illustration_09.png");
  background-size: cover;
  background-position: center;
  background-color: var(--linear-green);
}