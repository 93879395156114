

.ticketPresentation{
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  font-size: .95em;
  display: flex;
  flex-wrap: wrap;
}

.lastMessagePresentation{
  margin-top: 10px;
  color: black;
  display: flex;
  align-items: center;
}

.unreadMessages{
  background-color: #3b5999;
  color: white;
  border-radius: 50%;
  font-size: 0.85em;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solvedMessage{
  background-color: var(--green-kamix);
  color: white;
  border-radius: 5px;
  font-size: 0.8em;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticketPresentation-date{
  font-weight: 800;
  font-size: 0.9em;
  color: rgba(0,0,0,0.7);
}

.ticketPresentation-date-2{
  font-weight: 800;
  font-size: 0.8em;
  color: rgba(0,0,0,0.7);
}

.no-message{
  min-width: 400px;
}